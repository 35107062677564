/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'layout-text-sidebar': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 3a.5.5 0 000 1h5a.5.5 0 000-1zm0 3a.5.5 0 000 1h5a.5.5 0 000-1zM3 9.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m.5 2.5a.5.5 0 000 1h5a.5.5 0 000-1z"/><path pid="1" d="M0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2zm12-1v14h2a1 1 0 001-1V2a1 1 0 00-1-1zm-1 0H2a1 1 0 00-1 1v12a1 1 0 001 1h9z"/>',
    },
});
